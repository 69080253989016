import { type MetaFunction } from '@remix-run/node';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration } from '@remix-run/react';
import { useEffect, useRef } from 'react';
import styles from '~/tailwind.css';
import { useNonce } from './utils/nonce-provider.ts';

export const meta: MetaFunction = () => {
  return [{ title: 'Zamas' }, { name: 'description', content: 'Logiciel de gestion des services propreté' }];
};

export function links() {
  return [
  { rel: 'stylesheet', href: styles }];

}

export default function Root() {
  const htmlRef = useRef<HTMLHtmlElement>(null);
  const nonce = useNonce();

  useEffect(() => {
    if (htmlRef.current) htmlRef.current.classList.add('hydrated');
  }, []);

  return (
    <html lang="en" ref={htmlRef}>
			<head>
				<Links />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
			</head>
			<body className="font-sans thin-scrollbar">
				<Outlet />
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>);

}